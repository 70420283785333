<template>
  <form-section
    :id="sectionId"
    @visibility="onChangeSectionVisibility"
    v-if="loyaltyDetailsSectionComplete"
  >
    <form-group>
      <mobile-full-page-wrapper :id="termsAndConditionsId">
        <v-layout fill-height column justify-space-between>
          <v-flex>
            <v-layout>
              <v-flex :pr-5="$vuetify.breakpoint.lgAndUp" md8 lg8>
                <typography type="h3">
                  Great! Thanks for applying to have rewards added to your
                  {{ brand }} account.
                </typography>

                <typography>
                  To confirm your request please provide your acknowledgement
                  and select the
                  <strong>"Submit"</strong> button.
                </typography>
                <box>
                  <checkbox
                    v-model="hasAuthority"
                    name="HasAuthority"
                    :label="
                      `I confirm that I have authority over this ${brand} account`
                    "
                  />
                  <v-layout :pr-5="$vuetify.breakpoint.mdAndUp">
                    <checkbox
                      v-model="hasReadTermsAndConditions"
                      name="hasReadTermsAndConditions"
                    >
                      <div slot="label">
                        I have read and accept the
                        <a
                          v-if="hasFlyBuysTerms"
                          rel="noopener noreferrer"
                          :href="flybuysTermsLink"
                          name="DirectDebitTermsLink"
                          target="_blank"
                          @click.stop
                        >
                          Fly Buys Terms and Conditions
                        </a>
                        <span v-if="hasFlyBuysTerms && hasAirpointsTerms">
                          ,&nbsp;
                        </span>
                        <a
                          v-if="hasAirpointsTerms"
                          rel="noopener noreferrer"
                          :href="airpointsTermsLink"
                          name="ZTermsLink"
                          target="_blank"
                          @click.stop
                        >
                          Airpoints&trade; Collection Terms and Conditions
                        </a>
                        and the
                        <a
                          rel="noopener noreferrer"
                          :href="privacyPolicyLink"
                          name="PrivacyPolicyLink"
                          target="_blank"
                          @click.stop
                        >
                          privacy policy
                        </a>
                      </div>
                    </checkbox>
                  </v-layout>
                </box>
              </v-flex>
            </v-layout>

            <!-- Submit application button -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm6 md12>
                <round-button
                  name="applyForApplicationButton"
                  :disabled="formIsInvalid"
                  :loading="submitFormProgress.inProgress"
                  @click="onSubmitForm"
                >
                  Submit
                </round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import Box from '@/components/atoms/Box.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { mapGetters, mapState } from 'vuex';
import {
  SECTION_TERMS_AND_CONDITIONS,
  FIELD_TERMS_AND_CONDITIONS
} from '@/constants/html-ids';
import { FLYBUYS_TERMS_LINK, AIRPOINTS_TERMS_LINK } from '@/constants/routes';
import { BRAND, FLYBUYS, AIRPOINTS } from '@/constants/form';
import { sameAs } from 'vuelidate/lib/validators';
import {
  COMPLETED as COMPLETED_ROUTE,
  PRIVACY_POLICY_LINK
} from '@/constants/routes';

export default {
  components: {
    Typography,
    FormSection,
    FormGroup,
    Checkbox,
    RoundButton,
    TextInput,
    MobileFullPageWrapper,
    Box
  },
  computed: {
    hasAuthority: {
      get() {
        return this.$store.state.termsAndConditions.hasAuthority;
      },
      set(value) {
        this.$store.dispatch('termsAndConditions/changeHasAuthority', value);
      }
    },
    hasReadTermsAndConditions: {
      get() {
        return this.$store.state.termsAndConditions.hasReadTermsAndConditions;
      },
      set(value) {
        this.$store.dispatch(
          'termsAndConditions/changeHasReadTermsAndConditions',
          value
        );
      }
    },
    formIsInvalid() {
      // make sure all sections are valid
      const otherSectionsInvalid = [
        this.associationLevelInvalid,
        this.accountNumberInvalid
      ].some(v => v === true);

      return (
        this.$v.$invalid ||
        otherSectionsInvalid ||
        !this.associationDetailsIsValid
      );
    },

    ...mapGetters({
      associationDetailsIsValid: 'loyaltyDetails/associationDetailsIsValid',
      hasAirpointsTerms: 'loyaltyDetails/hasAirpointsLoyaltyScheme',
      hasFlyBuysTerms: 'loyaltyDetails/hasFlyBuysLoyaltyScheme'
    }),

    ...mapState({
      loyaltyDetailsSectionComplete: state =>
        state.loyaltyDetails.sectionComplete,
      submitFormProgress: state => state.progress.submitForm,
      associationLevelInvalid: state => state.associationLevel.invalid,
      accountNumberInvalid: state => state.accountNumber.invalid,
      associationDetails: state => state.loyaltyDetails.associationDetails
    }),
    sectionId: () => SECTION_TERMS_AND_CONDITIONS,
    termsAndConditionsId: () => FIELD_TERMS_AND_CONDITIONS,
    brand: () => BRAND,
    flybuys: () => FLYBUYS,
    flybuysTermsLink: () => FLYBUYS_TERMS_LINK,
    airpoints: () => AIRPOINTS,
    airpointsTermsLink: () => AIRPOINTS_TERMS_LINK,
    privacyPolicyLink: () => PRIVACY_POLICY_LINK
  },
  methods: {
    async onSubmitForm() {
      const success = await this.$store.dispatch('saver/submitForm');
      if (success) {
        this.$store.dispatch('termsAndConditions/changeSectionComplete', true);
        this.$router.push(COMPLETED_ROUTE);
      }
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_TERMS_AND_CONDITIONS
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_TERMS_AND_CONDITIONS
        );
      }
    }
  },
  validations: {
    hasAuthority: {
      sameAs: sameAs(() => {
        return true;
      })
    },
    hasReadTermsAndConditions: {
      sameAs: sameAs(() => {
        return true;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
</style>
